import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import "../../Builder_prompt_components/global_theme/fonts.css";

// import curve from "../../assets/curve.png";
import MotionBackground from "./MotionBackground";

import EmailLogin from "../../SignIn/EmailLogin";
import "../css/animation.css";
// import supabase from "../../../../config/supabse";

function Hero() {
  return (
    <>
      <div
        className="w-[99vw] min-h-[60vh] overflow-hidden relative h-auto flex justify-center items-center p-5 xl:p-10"
        style={{ backgroundColor: "transparent" }}
      >
        {/* <MotionBackground /> */}
        <div className="absolute z-20 flex flex-col items-center justify-center gap-6 mt-20">
          <h1 className="max-w-2xl px-2 text-4xl md:text-6xl font-light tracking-tighter text-center text-black font  manrope-400 animate-slide-up-delay-1 ">
            Welcome to the world's easiest website builder.
          </h1>
          <p className="font_inter mt-5 manrope-400 text-center text-[18px] leading-[1.4rem] font-[400] tracking-tighter max-w-lg lg:max-w-xl px-6 animate-slide-up-delay-2">
            Speed up the whole process of website creation, from information
            structure, copywriting to customizing and publishing your website{" "}
          </p>
          <div className="flex  mt-5 items-center max-w-[80%] mx-auto justify-center w-full animate-slide-up-delay-3">
            <EmailLogin ishome={1} />
          </div>
        </div>



 
      </div>
    </>
  );
}

export default Hero;
