import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="w-[99vw] overflow-hidden h-[80px] border-b border-b-gray-700 bg-black flex items-center  justify-center lg:justify-between px-16 py-4 gap-3">
      
      
      <a className="text-white font_inter text-md ">HelloWorld, Inc.</a>

      {/*    
    <div className='font_inter text-white text-md bg-white w-[4px] h-[4px]'></div> */}

      <div className="hidden lg:flex flex-row gap-5">
        <Link to="/privacy-policy" className="text-white font_inter text-md">
          Privacy Policy
        </Link>
        {/* <div className='font_inter text-white text-md bg-white w-[4px] h-[4px]'></div> */}
        <a className="text-white font_inter text-md ">Terms of Service</a>
      </div>
    </div>
  );
}

export default Footer;
