import React from "react";
import SectionImg from "../../../assets/Rectangle 261.png";
import "../css/custom.css";
function GetInFront() {
  return (
    <div className="px-0 lg:px-12 lg:py-20 bg-black">
      <div className="mx-auto space-y-12 px-7xl">
        <div className="flex flex-col items-center justify-center space-y-4 text-center  ">
          <h2 className="text-[30px] lg:text-[40px] font_manrope text-white mt-12">
            Get it in front of the world.
          </h2>
          <p className=" max-w-lg px-4 lg:max-w-xl text-white font_manrope">
            Make it easy for your audience, clients, or customers to show their
            support. Whether you’re selling content, your time, or sending out
            physical goods.
          </p>
          <a
            href="/"
            className="px-6 py-2 rounded-full bg-[#FFD488] text-black"
          >
            Join Waitlist
          </a>
        </div>
        <div className="w-full cards">
          <div className="w-full h-full">
            <img className="w-full h-full" src={SectionImg} alt="" />
          </div>
          <div className="flex flex-col w-full h-full gap-1">
            <div className="p-8 lg:p-14 flex-1 text-white bg-[#303030] space-y-2 font_manrope">
              <h1 className="text-[20px] lg:text-[24px]">
                Collect any type of payment
              </h1>
              <p>
                Sell anything from your site with the built-in Payments. From
                tips to subscriptions, downloads to memberships, you’re all set
                to start earning from your site.
              </p>
            </div>
            <div className="p-8 flex-1 lg:p-14 text-white bg-[#303030] space-y-2 font_manrope">
              <h1 className="text-[20px] lg:text-[24px]">
                Keep your bookings in order
              </h1>
              <p>
                If you’re selling any kind of service, automate booking
                sign-ups, cancellations, calendar integrations, and contact
                forms right from the start. More time for you.
              </p>
            </div>
            <div className="p-8 flex-1 lg:p-14 text-white bg-[#303030] space-y-2 font_manrope">
              <h1 className="text-[20px] lg:text-[24px]">
                From one-page site to full-on store
              </h1>
              <p>
                Building something beautiful is half of the story. HelloWorld
                takes it to the next level with simple but powerful built-in
                tools to promote and showcase your site.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetInFront;
