import React from 'react'
import '../../../MainPage/customsb.css'
import HorizontalHeader from '../HorizontalHeader/HorizontalHeader';
import { useSelector } from 'react-redux';
import ActivePlan from './ActivePlan';
import SuggestedPlan from './SuggestedPlan';
import UpsertPlan from '../UpsertPlan/UpsertPlan';

function UpgradePlan() {


    const CurrentPlanfeatures = [
        'Includes $5 of usage monthly',
        'Priority support included',
        'Access to all premium features',
        'Custom integrations available',
        'Up to 100,000 API calls per month'
    ];
    return (
        <>
        <UpsertPlan/>
           <HorizontalHeader headerTitle={"Plans"} />
            <div className='flex flex-col gap-10 overflow-auto w-full p-10 custom-scrollbar'>
                <section className="flex flex-col flex-grow w-full 2xl:w-[80%]  first:mt-0 last:mb-0">
                  <ActivePlan/>
                   <SuggestedPlan/>

                </section>


            </div>

        </>



    )
}

export default UpgradePlan