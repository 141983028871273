import React from "react";
import Profile from "../../DashboardAssets/images/profile.svg";
import { useSelector } from "react-redux";
const HorizontalHeader = (props) => {
  const Email = useSelector((state) => state.userDetails.email);
  const ActivePlan = useSelector((state) => state.userDetails.planDetails);

  return (
    <>
      <div
        className="sticky top-0 z-10 flex items-center justify-between p-4 bg-white border-b border"
      >
        <div className="flex items-center px-4">
          <div className="relative mx-auto text-[#000000] font-manrope font-semibold text-[20px] leading-[20px]">
            <p>{props.headerTitle}</p>
          </div>
        </div>
        <div className="flex items-center space-x-4 mr-4">
          <p className="text-[#000000] cursor-pointer text-[12px] bg-[#ffebc8] rounded-full px-3 py-1">
            {ActivePlan.title} Plan
          </p>
          {/* <!-- text --> */}
          <div className="flex items-center text-gray-600 hover:text-gray-800 dark:text-gray-200 dark:hover:text-gray-300">
            <img src={`https://api.dicebear.com/9.x/initials/svg?seed=4${Email}`} alt="Profile" className="h-9 w-9 rounded-full" />
          </div>
          {/* <!-- profile --> */}
        </div>
      </div>
    </>
  );
};

export default HorizontalHeader;
