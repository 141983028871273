import React, { useEffect, useState } from 'react'
import HorizontalHeader from '../HorizontalHeader/HorizontalHeader'
import { useParams } from 'react-router-dom'
import '../../../SignIn/spinner.css'
import supabase from '../../../../config/supabse'
import { MdOutlinePhoneEnabled } from "react-icons/md";
import { MdOutlineMail } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import { FiSend } from "react-icons/fi";
import { MdEdit } from "react-icons/md";
import { FaRegTrashAlt } from "react-icons/fa";
import './customscroll.css'
import ContactMessages from './ContactMessages'
import ContactNotes from './ContactNotes'
function ContactDetailCard() {
    const { credId, email } = useParams()
    const [contact, setContact] = useState(null);
    const [isLoading, setIsLoading] = useState(true)
    const formatContactName = (name) => {
        return name
            .trim() // Remove leading/trailing spaces
            .replace(/\s+/g, '+') // Replace spaces with pluses
            .replace(/[^a-zA-Z0-9+]/g, '-'); // Replace special characters (except +) with hyphens
    };
    useEffect(() => {
        const fetchContact = async () => {
            setIsLoading(true)
            try {
                const { data, error } = await supabase
                    .from('userContacts')
                    .select('*')
                    .eq('userId', credId)
                    .eq('contactEmail', email);

                if (error) {
                    setIsLoading(false)
                    console.error('Error fetching contact:', error);
                    setContact(null);
                } else {
                    setIsLoading(false)
                    console.log("Contact : ", data)
                    setContact(data[0]);
                }
            } catch (error) {
                setIsLoading(false)
                console.error('Unexpected error:', error);
                setContact(null);
            }
        };

        if (credId && email) {
            fetchContact();
        }
    }, [credId, email]);


    useEffect(() => {

        console.log("The Contacts ", contact)
    }, [contact])
    return (
        <>
            <HorizontalHeader headerTitle="Contacts" />
            {isLoading ? (
                <div className='flex items-center justify-center w-full h-full'>
                    <div className='spinner'></div>
                </div>
            ) : (contact && <div className=" bg-white w-full h-full p-10  overflow-auto">

                <div className='flex flex-row items-center  p-0 gap-5 w-full h-auto'>
                    <div className='flex w-[25%] bg-white border border-gray-200 rounded-md h-[35vh] '>
                        <div className="w-full bg-white p-0 rounded-t-lg flex flex-col items-center justify-between">
                            <div className="mt-10  flex items-center justify-center flex-col">
                                <span
                                    className="inline-block rounded-full overflow-hidden border-4 border-gray-100 "

                                    style={{
                                        width: '80px',
                                        height: '80px',
                                        minWidth: '100px',
                                        minHeight: '100px',
                                    }}
                                >
                                    <div
                                        className="w-full h-full rounded-full bg-cover bg-no-repeat bg-center"
                                        style={{
                                            backgroundImage:
                                                `url(https://api.dicebear.com/9.x/initials/svg?seed=4${formatContactName(contact?.contactName)}})`,
                                        }}
                                    ></div>
                                </span>
                                <div className="text-center mt-4 text-black ">
                                    <h2 className="text-xl font-semibold font-manrope leading-[14px]">
                                        {contact && contact?.contactName}
                                    </h2>
                                    <p className='text-zinc-600 mt-4 flex flex-row items-center justify-center gap-2'> <MdOutlineMail className='text-lg text-zinc-600' />  {contact && contact?.contactEmail}</p>
                                    <p className='text-zinc-600 mt-2 flex flex-row items-center justify-center gap-2'> <MdOutlinePhoneEnabled className='text-lg text-zinc-600' />  {contact && contact?.contactPhone}</p>

                                </div>

                            </div>

                            <a href={`https://mail.google.com/mail/u/0/?fs=1&to=${contact && contact?.contactEmail}&tf=cm`} className='bg-gray-50 w-full mt-5 h-[50px] flex items-center justify-center gap-2 hover:font-medium '>
                                <FiSend />
                                <p className='text-md '>Compose Email</p>

                            </a>

                        </div>

                    </div>
                 <ContactNotes/>

                </div>

                <div className='flex w-full h-auto mt-5 bg-white rounded-md '>
                    <ContactMessages/>

                </div>

            </div>)}

        </>
    )
}

export default ContactDetailCard