import React, { useEffect, useState } from "react";
import "../../Builder_prompt_components/global_theme/fonts.css";
import "../css/animation.css";
import { Link, useNavigate } from "react-router-dom";
import supabase from "../../../config/supabse";
import favicon from "../../../assets/blackborderfavicon.svg";
import { useSelector } from "react-redux";


function Navbar() {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [id, setId] = useState(null);
  const [token, setToken] = useState(null);
  const [auth, setAuth] = useState(false);
  const subDomain = useSelector((state) => { return state?.Name?.subdomain })

  const iconStyle = {
    fontVariationSettings: "'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24",
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const getSession = async () => {
      const { data, error } = await supabase.auth.getSession();
      if (error) {
        console.error("Error retrieving session:", error.message);
        return;
      }
      if (data && data.session?.user?.id && data.session.access_token) {
        setId(data.session.user.id);
        setToken(data.session.access_token);
        setAuth(true);
      }
    };
    getSession();
  }, []);

  return (
    <div className="flex flex-col items-center justify-center w-full overflow-x-hidden">
      <div className="w-full bg-white fixed top-0 z-50 h-[80px] flex items-center justify-between px-5 lg:px-[9vw] py-4 overflow-x-hidden">
        <Link to="/" className="flex flex-row items-center justify-center gap-3 cursor-pointer">
          <img
            src={favicon}
            className="w-[40px] h-[40px]"
            alt=""
          />
          <h1 className="flex font_manrope text-[20px] font-medium -tracking-[0.1px] "> HelloWorld  </h1>
        </Link>
        <div className="flex-row items-center hidden w-auto gap-8 px-10 lg:flex">
          <Link to="/about-us" className="font_inter text-md">  About Us  </Link>
          <Link to={`/pricing/${auth ? id : ''}`} className="font_inter text-md"> Pricing </Link>
          <Link to="/signin" className="font_inter text-md">  Log In </Link>
          {auth ? (
            <button
              onClick={() => {
                // if (!subDomain) {
                //   navigate(`/loading/${id}/${token}/2`);
                // } else {
                //   navigate(`/dashboard/${id}`);
                // }
                
                navigate(`/loading/${id}/${token}/2`);
                // navigate(`/main/${id}`);
              }


              }
              className="flex items-center justify-start px-6 py-2 text-sm text-white bg-black rounded-full font_inter"
            >
              dashboard {"->"}
            </button>
          ) : (
            <Link to="/" className="flex items-center justify-start px-6 py-2 text-sm text-white bg-black rounded-full font_inter">
              Join Waitlist
            </Link>
          )}
        </div>

        <div
          onClick={toggleMenu}
          className="flex self-end lg:hidden text-black hover:text-zinc-500 z-50 flex-col cursor-pointer w-[50px] h-[50px] items-center justify-center "
        >
          <span className="material-symbols-outlined text-[30px] font-thin" style={iconStyle}>
            {isMenuOpen ? 'close' : 'menu'}
          </span>
        </div>
      </div>

      {isMenuOpen && (
        <div className="h-screen font_inter bg-white py-5 pl-10 w-screen flex lg:hidden px-10 flex-col fixed z-[100] top-[80px]">
          <div className="flex flex-col justify-between h-full">
            <div>
              <Link
                to="/about-us"
                className="flex items-center self-start w-full py-4 text-lg font_inter"
              >
                About Us
              </Link>
              <Link
                to={`/pricing/${auth ? id : ''}`}
                className="flex items-center self-start w-full py-4 text-lg font_inter"
              >
                Pricing
              </Link>
              <Link
                to="/signin"
                className="flex items-center self-start w-full py-4 text-lg font_inter"
              >
                Log In
              </Link>
            </div>
            <div className="p-4 mb-4 text-center">
              <h2>HelloWorld, Inc.</h2>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Navbar;
