import React from "react";
// import img from "../../assets/dog.jpg";
import "../../Builder_prompt_components/global_theme/fonts.css";
// import h3 from "../../assets/h3.jpg";
import { useNavigate } from "react-router-dom";

function Explore() {
  const navigate = useNavigate();
  return (
    <div
      id="features"
      className="max-w-7xl mx-auto min-h-[60vh] h-auto py-10 bg-white flex  items-center flex-col gap-6 px-5 xl:px-10"
    >
      

      <div className="w-full space-y-12 text-left p-5  lg:p-10">
        <div className="space-y-2">
          <h2 className="text-[30px] lg:text-[40px] font_manrope">
            Effortless Creativity, Lightning Speed.
          </h2>
          <p className="max-w-2xl">
            Let your creativity fly with a website builder that's simple to set
            up and easy to customize. Take creator-friendly control of every
            last feature, layout, and detail.
          </p>
        </div>
        <div className="flex flex-col gap-5 lg:flex-row">
          <div className="bg-[#F5F5F5] w-full lg:w-[32%]  p-4 rounded-[5px]">
            <div className="p-2 space-y-2">
              <h2 className="text-[24px] font_manrope">Generate</h2>
              <p className="text-[16px]">
                Instantly create a fully functional website by simply describing
                your vision with a few prompts.
              </p>
            </div>
          </div>
          <div className="bg-[#F5F5F5] p-4 w-full lg:w-[32%]  rounded-[5px]">
   
            <div className="p-2 space-y-2">
              <h2 className="text-[24px] font_manrope">Customize</h2>
              <p className="text-[16px]">
                Fine-tune your site effortlessly by typing in any changes or
                adjustments you need, without any design skills required.
              </p>
            </div>
          </div>
          <div className="bg-[#F5F5F5] p-4  w-full lg:w-[32%] rounded-[5px]">
        
            <div className="p-2 space-y-2">
              <h2 className="text-[24px] font_manrope">Publish</h2>
              <p className="text-[16px]">
                Launch your polished website with a single click, ready to reach
                your audience online. instantly optimized for all devices.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Explore;
