import React from "react";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Explore from "../components/Explore";
import Community from "../components/Community";
import WaitList from "../components/WaitList";
import DND from '../../MainPage/DND'
import GetInFront from "../components/GetInFront";
import Video from "../components/Video";
function HomePage(props) {
  return (
    <div className="w-full overflow-x-hidden h-auto ">
      <Navbar credId={props.credId} token={props.token} />
      <Hero />
      <Video/>
      <Explore />
      <GetInFront />
      <Community />
      <WaitList />
    
      <Footer />
    </div>
  );
}

export default HomePage;
