import React, { useEffect, useRef } from "react";
import "../../../components/Builder_prompt_components/global_theme/fonts.css";
import './images.css'
function Community() {
  

  const sliderRef = useRef(null);

  // useEffect(() => {
  //   if (sliderRef.current) {
  //     const slider = sliderRef.current;

  //     // Function to create and append an image
  //     const appendImage = () => {
  //       const newImage = document.createElement('img');
  //       newImage.src = 'https://s3-alpha-sig.figma.com/img/e0fc/8562/c6fe41b05bea9dda84c281584772c05b?Expires=1724630400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=cn598e2zXFBPwtJh6VJozuJiPehEk7gTgA57NtPm5lx4yPUHp66Wlp3VYm~yncR9WKE73mCAeb4nI8YeFoOFwGuZ8ZQayvKYoqmQliL06~Y2d-epw4iWTiQnlmRKpCR5zJ4zwlaMD3c5y1HrEjCEzfNG5GYeBjbZUDjfELQ7S3~FqVUxw7HGPRjicj2ZkaoIJgnt7-okWEWZWNlms5QzDwo~pZwohIKie8CdqrF4Rb6NyUjTdrcTsSVZVLndz9L8~P8CZepUIC~pIuFe9rmGOx1KWtxulpBtjs9eA1wn3vr3yJVgwRi-VkGFTvtdhlG3fnaAe~T56vpeBrL8uQunHA__';
  //       newImage.alt = 'Logo';
  //       newImage.style.height = '500px';
  //       newImage.style.borderRadius = '10px';
  //       newImage.style.margin = '0 10px';

  //       slider.appendChild(newImage);
  //     };

  //     // Append an image every second
  //     const intervalId = setInterval(appendImage, 1000);

  //     // Clear the interval when the component unmounts
  //     return () => clearInterval(intervalId);
  //   }
  // }, []);



  return (
    <div className="pt-20 ">
      {" "}
      <div className="px-8 mx-auto space-y-2 max-w-7xl">
        <div className="space-y-4 font_manrope">
          <h2 className="text-[30px] lg:text-[40px]">Explore our community </h2>
          <p>See what others have created with Helloworld</p>
        </div>
      </div>

      <div className="logos mt-20">
      <div className="logos-slide" ref={sliderRef}>
        <img src="https://plus.unsplash.com/premium_photo-1661277816311-28cced31f998?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Logo 1" />
        <img src="https://images.unsplash.com/photo-1519389950473-47ba0277781c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Logo 2" />
        <img src="https://images.unsplash.com/photo-1498049860654-af1a5c566876?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Logo 3" />
        <img src="https://plus.unsplash.com/premium_photo-1661277816311-28cced31f998?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Logo 1" />
        <img src="https://images.unsplash.com/photo-1519389950473-47ba0277781c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Logo 2" />
        <img src="https://images.unsplash.com/photo-1498049860654-af1a5c566876?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Logo 3" />
        <img src="https://plus.unsplash.com/premium_photo-1661277816311-28cced31f998?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Logo 1" />
        <img src="https://images.unsplash.com/photo-1519389950473-47ba0277781c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Logo 2" />
        <img src="https://images.unsplash.com/photo-1498049860654-af1a5c566876?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Logo 3" />
        <img src="https://plus.unsplash.com/premium_photo-1661277816311-28cced31f998?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Logo 1" />
        <img src="https://images.unsplash.com/photo-1519389950473-47ba0277781c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Logo 2" />
        <img src="https://images.unsplash.com/photo-1498049860654-af1a5c566876?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Logo 3" />
        <img src="https://plus.unsplash.com/premium_photo-1661277816311-28cced31f998?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Logo 1" />
        <img src="https://images.unsplash.com/photo-1519389950473-47ba0277781c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Logo 2" />
        <img src="https://images.unsplash.com/photo-1498049860654-af1a5c566876?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Logo 3" />
        <img src="https://plus.unsplash.com/premium_photo-1661277816311-28cced31f998?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Logo 1" />
        <img src="https://images.unsplash.com/photo-1519389950473-47ba0277781c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Logo 2" />
        <img src="https://images.unsplash.com/photo-1498049860654-af1a5c566876?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Logo 3" />
        <img src="https://plus.unsplash.com/premium_photo-1661277816311-28cced31f998?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Logo 1" />
        <img src="https://images.unsplash.com/photo-1519389950473-47ba0277781c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Logo 2" />
        <img src="https://images.unsplash.com/photo-1498049860654-af1a5c566876?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Logo 3" />
      </div>
    </div>
     
    </div>
  );
}

export default Community;
