import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setUserId } from '../../Store/Slices/userIdSlice';
import { setAssets } from '../../Store/Slices/AssetsSlice';
import { setIdea } from '../../Store/Slices/IdeaSlice';
import { setDomain, setName_ } from '../../Store/Slices/NameSlice';
import { setImageQuery, setImages, setUsed } from '../../Store/Slices/ImageQuerySlice';
import { setColorArray, setFonts, setHue, setSiteId } from '../../Store/Slices/ColorSlice';
import { initalizeUserSlice, setPageHtmlState, setUserHtml } from '../../Store/Slices/userHtmlSlice';
import { setLogo } from '../../Store/Slices/LogoSlice';
import { setSectionArray } from '../../Store/Slices/SectionSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { ProgressBar } from 'primereact/progressbar';
import '../Builder_prompt_components/global_theme/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import supabase from '../../config/supabse';
import { MultiStepLoader } from '../UI/MultiStepLoader';
import './spinner.css'



function LoadingData(props) {
  
  const { credId, token, linkId } = useParams()
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const Idea = useSelector((state) => { return state.Idea.data })
  const Name = useSelector((state) => { return state.Name.data })
  const Color = useSelector((state) => { return state.Color.data })
  const Section = useSelector((state) => { return state.Section.data })
  const Logo = useSelector((state) => { return state.Logo.data })
  const Data = useSelector((state) => state.userHtml.data);
  const ImageQuery = (useSelector(state => state.Query.data));
  const Assets = (useSelector(state => state.Assets.data));
  const userId = (useSelector(state => state.userId.data));
  const [redirect, setRedirect] = useState(0)
  const [credentialId, setCredentialId] = useState('')
  const [stoken, setStoken] = useState('')


  // const page_index = (useSelector(state => state.userHtml.index));
  // const isSiteCreated_ = (useSelector(state => state.userHtml.isSiteCreated));
  // console.log("Page _ index  =  > ", page_index)
  // const dt = useSelector(state => state.userHtml.pages[page_index])
  // const [data__, setData__] = useState(useSelector(state => state.userHtml.pages[page_index]));
  var html = ''
  var webPage = []
  function manipulate_section_string(_id, htmlTemplate) {
    var htmlStr = htmlTemplate;

    if (htmlStr.startsWith('"') && htmlStr.endsWith('"')) {
      htmlStr = htmlStr.slice(1, -1);
    }
    if (htmlStr.startsWith('\'') && htmlStr.endsWith('\'')) {
      htmlStr = htmlStr.slice(1, -1);
    }
    webPage.push(htmlStr)
  }

  useEffect(() => {

    const fetchData = async (userCrendentialId, token) => {
      // setCredentialId(userCrendentialId)
      // setStoken(token)
      try {
        const { data, error } = await supabase
          .from('histories')
          .select('*')
          .eq('userId', userCrendentialId);

        if (error) {
          
          console.log(error)
          throw error;
        }

        console.log('Histories:', data);
        if (data && data?.length > 0) {
          console.log("UserData : ", data)
          const row = data[0];
          row.webIdea && dispatch(setIdea(row.webIdea))
          row.webName && dispatch(setName_(row.webName))
          row.webColor && dispatch(setColorArray(row.webColor))
          row.hue && dispatch(setHue(row.hue))
          row.webTheme.fonts && row.webTheme.fonts && dispatch(setFonts(row.webTheme.fonts))
          row.siteId && dispatch(setSiteId(row.siteId))
          console.log("Loaded User Assets : ", data.userAssets)

          // Check if queries is an array and get the first 5 entries
          if (Array.isArray(row?.userAssets?.queries)) {
            const firstFiveEntries = row?.userAssets?.queries.slice(0, 5);
            row.userAssets && row.userAssets.queries && dispatch(setImageQuery(firstFiveEntries));

          } else {
            console.error('queries is not an array');
          }
          row.userAssets && row.userAssets.images && dispatch(setUsed(row.userAssets.images));
          row.userAssets && row.userAssets.used && dispatch(setImages(row.userAssets.used));
          row.domain && dispatch(setDomain(row.domain));
          row.userData && dispatch(initalizeUserSlice(row.userData))
          row.webSectionType && dispatch(setSectionArray(row.webSectionType))

          const data__ = row?.userData["Home"]
          if (data__ && data__?.length > 0) {
            for (const element of data__) {
              manipulate_section_string(element._id, element.htmlTemplate)
            }
            html = webPage.join('')
            console.log("THE HTML COMBINED FOR SS = ", html)
            dispatch(setPageHtmlState(html))
          }


          console.log("The linkId = ", linkId)
          if (parseInt(linkId) === 1) {
            navigate(`/main/${userCrendentialId}`);
          } else {
            navigate(`/dashboard/${userCrendentialId}`);
          }

        }
        else {
          console.log("idea redirecting")
          navigate(`/idea/${userCrendentialId}/${token}`);
          setRedirect(2)
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchData(credId, token)
  }, [])





  const [loading, setLoading] = useState(true);
  const loadingStates = [
    {
      text: "Collecting Data",
    },
    {
      text: "Adjusting Layout",
    },
    {
      text: "Loading Images",
    },
    {
      text: "Initializing Editor",
    },
    {
      text: "Integrating Pages",
    },
    {
      text: "Almost There",
    },
    {
      text: "Website Loaded",
    },
  ];

  return (
    <>
      <div className='flex items-center justify-center min-h-screen flex-col'>
        <div className='spinner'></div>
        <div>
        </div>
      </div>

    </>
  )
};

export default LoadingData
