import React, { useEffect, useState, useSyncExternalStore } from 'react'
import {FcGoogle} from 'react-icons/fc'
import './Signin.css'
import '../Builder_prompt_components/global_theme/fonts.css'; // Replace with your theme
import { Link, useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import placeholder1 from '../../assets/placeholder1.svg'
import EmailLogin from './EmailLogin';
import video from '../../assets/video.mp4'
import Cookies from 'js-cookie';
import supabase from '../../config/supabse';



function Signin() {
  const navigate = useNavigate();  
  const [email, setEmail] =useState('')
  const [emailSent, setEmailSent]=useState(false)
  const [inserting, setInserting] =useState(false)

  
  // async function insertUser() {
  //   if (email === null) {
  //     showRejectMessage("Enter Email");
  //     return;
  //   }
  //   setInserting(true);
  //   let { data, error } = await supabase.auth.signInWithOtp({
  //     email: email,
  //   });
  //   if (data) {
  //     setEmailSent(true);
  //     setTimeout(() => {
  //       setEmailSent(false);
  //     }, 10000);
  //     console.log(data);
  //   }
  //   setInserting(false);
  // }


  async function insertUser() {
    if (email === null) {
      showRejectMessage("Enter Email");
      return;
    }
  
    // Query the wishlist table to check if the email exists and access is true
    const { data: wishlistData, error: wishlistError } = await supabase
      .from('wishlist')
      .select('*')
      .eq('email', email)
      .eq('access', true)
      .single(); // Use single() to fetch a single row
  
    if (wishlistError) {
      showRejectMessage("You dont have access to use the app. Join Waitlist or Contact Us.");
      console.error(wishlistError);
      return;
    }
  
    if (!wishlistData) {
      showRejectMessage("Please join waitlist");
      return;
    }
  
    setInserting(true);


    console.log("The Email You have Entered : ", email)
  
    // Proceed to sign in with OTP
    const { data, error } = await supabase.auth.signInWithOtp({
      email: email,
    });
  
    if (data) {
      setEmailSent(true);
      setTimeout(() => {
        setEmailSent(false);
      }, 10000);
      console.log("OTP:",data);
    }
  
    if (error) {
      showRejectMessage("Error sending OTP" );
      console.error(error);
    }
  
    setInserting(false);
  }
  

  const showSuccessMessage = (msg) => {
    toast.success(msg, {
    position: "top-right",
      autoClose: 3000, 
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined, 
      icon:""
    });
  };

  
  const showRejectMessage = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 3000, 
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
     
    });}



 
  // const fetchProfileInfo = async (accessToken) => {
  //   try {
  //     const response = await fetch('https://www.googleapis.com/oauth2/v1/userinfo', {
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //     });
  
  //     if (response.ok) {
  //       const profile = await response.json();
  //       console.log('User Profile Information:', profile);
  //       if(profile){
  //         handleRegister(profile.id, profile.email)
  //       }
  //       return profile;
  //     } else {
  //       console.error('Failed to fetch profile information:', response.statusText);
  //       // Handle the error
  //       return null;
  //     }
  //   } catch (error) {
  //     console.error('Error fetching profile information:', error);
  //     // Handle the error
  //     return null;
  //   }
  // };
  
  // // Usage with your access token


  // const loginWithGoogle = useGoogleLogin({
  //   onSuccess: credentialResponse => {console.log("crdential : ", credentialResponse)
  //   const accessToken = credentialResponse.access_token; 
  //   fetchProfileInfo(accessToken);

  //   },
  //   onError:Error =>{console.log(Error)}
  // });



  return (

    <div key="1" className="w-full h-screen flex overflow-y-hidden">
   <div className="w-2/3 h-full overflow-hidden relative hidden xl:flex ">
      <div
        style={{
          position: 'absolute',
          zIndex: -1,
          overflow: 'hidden',
          width: '100%',
          height: '100%',
          transform: 'translateX(-0px)',
        }}
      >
        <video
          src={video}
          autoPlay
          loop
          muted
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      </div>
    </div>
      <div className="w-full xl:w-1/3 flex items-center justify-center bg-black">
        <div className="w-4/5 bg-black border-0 rounded-lg">
          <div className="flex justify-start text-3xl font-bold text-white mb-0 manrope-400">HelloWorld</div>
          <p className='text-zinc-300 manrope-400'>Enter your email address to get email link to builder. </p>
          <div className="space-y-2 mt-7">
            {/* <div className="text-left text-sm text-white mb-3 font_inter">
              We'll sign you in or create an account if you don't have one yet.
            </div>
            <button
              className="w-full h-[45px] flex justify-between items-center rounded-[10px] hover:bg-black hover:text-white bg-white font_inter px-3 text-sm hover:border-white"
              variant="outline"
              onClick={()=>{loginWithGoogle()}}
            >
              Login with Google
              <ChromeIcon className="w-4 h-4 ml-2" />
            </button> */}
{/* 
            <EmailLogin/>
            <div className="text-left text-xs text-white mt-5 font_inter mb-5">Rather use email and password?</div>
            <Link to="/login/e&p" className="text-center text-xs font_inter text-[#FFD487] underline mt-1" href="#">
              Continue with email and password
            </Link> */}


            <div className="space-y-1">
            <input     
            className={`w-full h-[45px] rounded-[10px] flex border font_inter border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent  file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 `}
            type="email"
            value={email}
            onChange={(e)=>{setEmail(e.target.value)}}
            style={{ '--tw-ring-color': 'rgb(169 169 169 / 50%)' }}
            placeholder="log in with email" />

            </div>
            {!inserting ? (
                <button
                onClick={()=>{insertUser()}}
                  className="w-full h-[45px] flex justify-start px-3 font_inter text-sm items-center bg-[#FFD488] text-black rounded-[10px] hover:border-white"
                  type="submit"
                >
                  Continue with email
                </button>
            ) : (  <button
    
                className="w-full h-[45px] flex justify-start px-3 font_inter text-sm items-center bg-[#e9c78e] text-black rounded-[10px] hover:border-white"
                type="submit"
              >
                Continue with email
              </button>)}

             { emailSent ?  (<p className='text-white manrope-400'>
              The email has been sent to your account
             </p>) : (<></>) }
          


          </div>
        </div>
      </div>
      <ToastContainer/>
    </div>
  
  )
}

export default Signin



function ChromeIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10" />
      <circle cx="12" cy="12" r="4" />
      <line x1="21.17" x2="12" y1="8" y2="8" />
      <line x1="3.95" x2="8.54" y1="6.06" y2="14" />
      <line x1="10.88" x2="15.46" y1="21.94" y2="14" />
    </svg>
  )
}





  // <div className='flex items-center justify-center min-h-screen'>
    //     <div className='flex items-center justify-center flex-col p-2 w-auto' >
    //         <h1 className='text-4xl font_new_spirit'>Hello World</h1>
    //         <div className='flex items-center justify-center flex-col p-6 mt-6 md:min-w-[400px] w-auto bg-white border-dv'>
    //         <Link to="/login/e" className='border-grey_color-300 hover:border-grey_color-400 text-center text-sm font_new_spirit border-[1px] rounded-xl text-grey_color-500 px-4 py-2 w-[90%] bg-grey_color-200 mt-4' >Log in with email</Link>            
    //                 <button onClick={()=>{loginWithGoogle()}} className='mt-2 hover:border-grey_color-400 font-normal font_new_spirit text-sm flex flex-row justify-center items-center text-grey_color-500 bg-grey_color-200 px-4 py-2 w-[90%] border-grey_color-300 border-[1px] rounded-xl'>Continue with Google <span><FcGoogle  className='ml-3' size={"25px"}/></span> </button>
    //                 <div className='mt-3 w-[90%] flex flex-row justify-center items-center'>
    //                     <div className='w-[40%] h-[0.5px] bg-grey_color-400 border-0'></div>
    //                     <p className='px-3 text-grey_color-500 font_new_spirit'>or</p>
    //                     <div className='w-[40%] h-[0.5px] bg-grey_color-400 border-0'></div>
    //                 </div>
    //               <Link to="/signup/" className='mt-3 font-normal font_new_spirit text-sm flex flex-row justify-center items-center bg-black hover:bg-[#1b1b1b] text-white text-center px-4 py-2 w-[90%] rounded-xl'>Create an Account</Link>
    //        </div>
    //        </div> 
    //      
    // </div>