import React, { useRef, useState } from 'react';
import video from '../../../assets/appvid.mp4';
import { FaPlay, FaPause } from 'react-icons/fa'; // Using react-icons for SVG icons

function Video() {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  return (
    <div className="w-full items-center justify-center flex p-10 xl:p-20">
      <div className="relative w-full max-w-6xl aspect-w-16 aspect-h-9 flex items-center justify-center rounded-[15px] xl:rounded-[30px] bg-black overflow-hidden">
        <video
          ref={videoRef}
          src={video}
          className="w-full h-full"
          onClick={handlePlayPause}
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
        />
        <button
          className={`absolute inset-0 flex items-center justify-center transition-opacity ${isPlaying ? 'opacity-0' : 'opacity-100'} hover:opacity-100`}
          onClick={handlePlayPause}
        >
          {isPlaying ? 
          
          (<div className='bg-primary-200 w-[70px] h-[70px] rounded-full flex items-center justify-center'><FaPause className="text-white text-4xl" /> </div>)

          : (<div className='bg-primary-200 w-[70px] h-[70px] rounded-full flex items-center justify-center'><FaPlay className="text-white text-4xl" /> </div>)}
        </button>
      </div>
    </div>
  );
}

export default Video;
